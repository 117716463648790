<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 面膜活动</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
				
                <!--<el-button type="danger" icon="delete" class="handle-del mr10" @click="delAll">批量删除</el-button>-->
                <!--<el-select v-model="select_cate" placeholder="筛选省份" class="handle-select mr10">-->
                    <!--<el-option key="1" label="广东省" value="广东省"></el-option>-->
                    <!--<el-option key="2" label="湖南省" value="湖南省"></el-option>-->
                <!--</el-select>-->
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
<!-- 				<el-button type="primary" @click="handleConfig">活动设置</el-button>
				 -->
                <el-select v-model="selectedStatus" class="mr10" @change="change" style="margin-left: 50px;">
                    <el-option
                            v-for="item in statusList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">{{item.title}}
                    </el-option>

                </el-select>
				
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="tel" align="center" label="手机号" :show-overflow-tooltip='true'>
                </el-table-column>
                <el-table-column prop="code" align="center" style="color: red;" label="兑换码" :show-overflow-tooltip='true'>
                </el-table-column>
                <el-table-column prop="name" align="center" width="150" label="咨询姓名">
                </el-table-column>

                <el-table-column prop="state" align="center" width="180" label="是否使用" sortable>
                    <template slot-scope="scope">
                        <div v-if="scope.row.state==1" style="color:green; cursor:pointer; font-size:22px;" @click="isUpSwitch(scope.$index,scope.row)"><i class="el-icon-circle-plus">待使用</i></div>
                        <div v-if="scope.row.state==2" style="color:red; cursor:pointer; font-size:22px;" @click="isUpSwitch(scope.$index,scope.row)"><i class="el-icon-remove">已使用</i></div>
						<div v-if="scope.row.state==3" style="color:#606266; cursor:pointer; font-size:22px;" ><i class="el-icon-time">已过期</i></div>
					</template>
                </el-table-column>
                <el-table-column prop="datetime" label="领取时间" width="180" align="center" sortable>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope"> 
                        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>
		<el-dialog title="活动设置"  v-loading='loading' :visible.sync="dialogEmail" width="70%">
			<el-form :model="emailData" ref="emailData" :rules="rules" label-width="150px">
				<el-form-item label="是否开启活动">
				    <el-switch v-model="emailData.status"></el-switch>
				</el-form-item>
				<el-form-item label="标题" prop="title">
				    <el-input v-model="emailData.title" style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="数据填充" prop="data">
				    <el-input v-model="emailData.data" style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="数据填充2" prop="data">
				    <el-input v-model="emailData.data2" style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="数据填充3" prop="data">
				    <el-input v-model="emailData.data3" style="width:350px"></el-input>
				</el-form-item>
                <el-form-item label="活动开始" prop="start_date">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.start_date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动结束" prop="end_date">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.end_date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动图">
                    <el-upload
                            class="avatar-uploader"
                            name="image"
                            with-credentials
                            :data="{id:this.emailData.imgid}"
                            :action="uploadUrl()"
                            :on-error="uploadError"
                            :on-success="handleAvatarSuccess2"
                            :before-upload="beforeAvatarUpload"
                            :on-progress="uploading"
                            :show-file-list="false"
                            :auto-upload="true"
                            enctype="multipart/form-data">
                        <img style="width: 100px;" v-if="emailData.b_image" :src="emailData.b_image" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span style="color:red">建议尺寸550*550</span>
                </el-form-item>
				<el-form-item label="二维码背景图片">
				    <el-upload
				            class="avatar-uploader"
				            name="image"
				            with-credentials
				            :data="{id:this.emailData.back_img}"
				            :action="uploadUrl()"
				            :on-error="uploadError"
				            :on-success="handleAvatarSuccess"
				            :before-upload="beforeAvatarUpload"
				            :on-progress="uploading"
				            :show-file-list="false"
				            :auto-upload="true"
				            enctype="multipart/form-data">
				        <img style="width: 100px;" v-if="emailData.backimgUrl" :src="emailData.backimgUrl" class="avatar">
				        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				    </el-upload>
				</el-form-item>
                <el-form-item label="活动规则">
                    <vue-ueditor-wrap v-model="emailData.description" :config="myConfig"></vue-ueditor-wrap>
                </el-form-item>
						
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogEmail = false">关 闭</el-button>
				<el-button type="primary" @click="saveConfig('emailData')">确 定</el-button>
			</span>
		</el-dialog>


        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor, Quill} from 'vue-quill-editor'
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend)
    export default {
        name: 'basetable',
        components: {
            quillEditor
        },
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id: '',
                    casemenuid: '',
                    casemenu: '',
                    ask: '',
                    answer: '',
                    looknum: '',
                    sort: '',
                    datetime: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                dialogImageUrl: '',
                isShowBigImg: false,
                // 富文本框参数设置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'image',
                            action: this.$api.uploadUrl+"/Images/uploadEditorImage",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
                //规格
                inputVisible: false,
                inputValue: '',
                loading:false, //加载中
                goodsList:[],  //菜单列表
                type: [],  //被选中的菜单列表
                caseList:[],  //日记分类列表
				emailData:{
					 id:'',
					 title:'',
					 president_fir:'',
					 president_fir_img:'',
					 imgid: null,
					 b_image: null,
					 status: '',
					 back_img: null,
					 backimgUrl: null,
				},
				dialogEmail:false,
				myConfig: {
				    // 编辑器不自动被内容撑高
				    autoHeightEnabled: false,
				    // 初始容器高度
				    initialFrameHeight: 600,
				    // 初始容器宽度
				    initialFrameWidth: '90%',
				    zIndex: 9999,
				    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				    serverUrl: this.$api.editorUrl,
				    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				    UEDITOR_HOME_URL: '/ueditor/'
				},
				rules: {
					title: [
                        { required: true, message: '请输入标题', trigger: 'blur' }
                    ],
					start_date: [
						{ required: true, message: '活动开始时间', trigger: 'blur' }
					],
					end_date:[
						{ required: true, message: '活动结束时间', trigger: 'blur' }
					],
				},
				selectedStatus:0,
				statusList:[
				    { id: 0, title: "全部"},
				    { id: 1, title: "待使用"},
				    { id: 2, title: "已使用"},
				    { id: 3, title: "已过期"},
				],
            }
        },
        created() {
            this.getData();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.title === this.del_list[i].title) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
					
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            onEditorChange({ editor, html, text }) {
                this.tableData.details = html;
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number,
					state: this.selectedStatus,
                });
                // console.log(params);
                this.$api.post('ShopActivity/getList', params, res => {
					
                    this.tableData = res.data.list;

                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(res);
                }, err => {
                    this.tableData = [];
                    this.sumPage = 1*10;
                    this.cur_page = 1;
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
				this.selectedStatus = 0;
                this.getData();
            },
            formatter(row, column) {
                return row.url;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleDelete(index, row) {
                this.idx = index;
                //this.tableData = row;
				this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 确定删除
            deleteRow(){
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                // console.log(this.form.id);
                // return;
                this.$api.post('ShopActivity/delete', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.tableData.details);
                console.log(str);
            },
            //切换
            isUpSwitch(index, options){
                let _this=this;
                let msg="";
                if(options.state==2){
                    msg="核对兑换码确认取消使用吗？"
                }else{
                    msg="核对兑换码确认使用吗？"
                }
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
					if(options.state==2){
						var isUp =1;
					}else{
						var isUp =2;
					}
                    var params=this.$qs.stringify({
                        id: options.id,
                        state: isUp
                    });
                    this.$api.post('ShopActivity/isUpSwitch', params, res => {
                        _this.tableData[index].isup = !options.isup;
                        if(isUp==1){
                            this.$message({
                                type: 'success',
                                message: '修改状态成功'
                            });
                        }else{
                            this.$message({
                                type: 'info',
                                message: '修改状态成功'
                            });
                        }
						this.getData();
                    }, err => {
                        console.log(err);
                        this.$message.error(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作已取消'
                    });
                });


            },			  
			handleConfig(){
				var params = this.$qs.stringify({
					activity_type:"mianmo_activity",
				})
				this.dialogEmail = true
				this.$api.post('ShopActivity/system_set', params,res=>{
					this.emailData =  res.data.list
				 
				},err => {
				this.emailData = []
				this.$message.error(err.msg);
				});
			},
			saveConfig(formName){
				
                this.$refs[formName].validate((valid) => {
                    if (valid) {
						if(!this.emailData.title){
							this.$message.error('请输入标题');
							return false;
						}
						if(!this.emailData.start_date){
							this.$message.error('请选择开始时间');
							return false;
						}
						if(!this.emailData.end_date){
							this.$message.error('请选择结束时间');
							return false;
						}
						var params=this.$qs.stringify({
							status:this.emailData.status ? 1 : 0,
							activity_type:"mianmo_activity",
							title:this.emailData.title,
							data:this.emailData.data,
							data2:this.emailData.data2,
							data3:this.emailData.data3,
							start_date:this.emailData.start_date,
							end_date:this.emailData.end_date,
							description:this.emailData.description,
							imgid:this.emailData.imgid,
							back_img: this.emailData.back_img,
						});

						this.$api.post('ShopActivity/saveSetting', params, res => {
							this.dialogEmail =  false;
							this.$message.success(res.msg);
							this.getData();
						}, err => {
							this.$message.error(err.msg);
						});
				
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });				
				
				
			},
			handleAvatarSuccess2(res, file){
				this.loading=false;
			    this.emailData.imgid=res.data;
			    this.emailData.b_image = URL.createObjectURL(file.raw);

			    //this.getData();
				this.handleConfig();
			    this.$message.success(res.msg);
			},			//图片上传成功
			//设置上传图片接口地址
			uploadUrl(){
			  var url=this.$api.uploadUrl + "/Images/upload";
			  return url
			},
			handleClick(tab, event) {
			  // console.log(tab, event);
			},
			//图片上传之前
			beforeAvatarUpload(file){
			  // console.log(file);
			    this.loading=true;
			},
			//正在上传中
			uploading(event, file, fileList){
			  // console.log(event);
			  // console.log(file);
			  // console.log(fileList);
			},
			//图片上传失败
			uploadError(err){
			  this.$message.error(err.msg);
			},
			//图片上传成功
			handleAvatarSuccess(res, file){
			  this.loading=false;
			  //console.log(res);
			  this.emailData.back_img=res.data;
			  //console.log(res.data);
			  
			  this.emailData.backimgUrl = URL.createObjectURL(file.raw);
			  this.$message.success(res.msg);
			},			  //图片上传成功
			change(){
			    console.log(this.selectedStatus);
			    this.getData();
			},
        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 30px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 110px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .ql-snow .ql-editor img{
        vertical-align: top !important;
    }
</style>
